// Import global Styles
@import "./global";

// Contact Styles
.contact-form{
    width: 100%;
    @include flexColumn();
    margin-top: 5rem;
    padding: 5rem 0;
    
    h2{
        font-weight: bold;
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: #363636;
    }

    .contact-icon{
        color:  $yellow;
    }

    .span-title{
        height: 5px;
        width: 70px;
        background-color: $yellow;
        margin-top: 1.5rem;
    }

    .contact-container{
        width: 100%;
        @include flexColumn();

        form{
            width: 70%;
    
            .form-alert{
                width: 80%;
                margin-bottom: 1rem;
            }

            .form-container{
                @include flexRow();
                width: 80%;
                margin-bottom: 1rem;

                .input-text{
                    width: 80%;
                    margin: 1.5rem;
                    padding: 1rem 0;
                }
        
                .text-area{
                    padding: 1rem 0;
                }
    
                .paneles-type{
                    width: 51%;
    
                    .MuiFormControl-root{
                        width: 100% !important;
                        margin: 0 !important;
                    }
                }
            }
    
            .contact-button{
                background-color: $black;
                width: 150px;
                margin-top: 1rem;
                padding: 10px 60px;
                border: 2px solid $black;
                border-radius: 30px;
                transition: 1s;
                cursor: pointer;
                color: $white;
                @include flexColumn;
    
                &:hover{
                    background-color: $white;
                    color: $black;
                }
            }
        }
    }
}

// Media Querys
@media screen and (max-width: 992px) {
    .contact-form{
        margin: 3rem 0;
        padding: 0 1rem;

        .contact-container{
            form{
                width: 100%;
    
                .form-container{
                    width: 100%;
                    @include flexColumn();
    
                    
                    .input-text , .form-select{
                        width: 100% !important;
                        margin: 1rem 0;
                    }
                }
            }
          }
    }
}

@media screen and (max-width: 792px) {
    .contact-form{
         h2{
             font-size: 2rem;
         }
    }
}